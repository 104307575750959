require("fslightbox");
import Swiper, { Autoplay } from 'swiper';
const $ = jQuery;

Swiper.use([Autoplay]);

new Swiper('.swiper', {
	slidesPerView: 8,
	spaceBetween: 0,
	effect: 'slide',
	loop: true,
	freeMode: {
		enabled: true,
	},
	centeredSlides: true,
	speed: 6000,
	autoplay: {
		delay: 1,
		disableOnInteraction: false,
	}
});

$('[data-quote-id]').on('click', function(e) {
	e.preventDefault();
	$('body').addClass('modal-open');
	let thisQuote = $(this).data('quote-id');
	$('input[name="listing_id"]').val(thisQuote);
	$('.joosup-form-quote-popup').fadeIn(200);
});

let listingId = $('[data-listing-id]');
if (listingId.length > 0) {
	let listId = listingId.data('listing-id');
	$('input[name="listing_id"]').val(listId);
}

$('[data-close]').on('click', function(e) {
	e.preventDefault();
	$('body').removeClass('modal-open');
	$('.joosup-form-quote-popup').fadeOut(200);
});
